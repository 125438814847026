/**
 * 
 * Pinia Store: egy-egy oldal beallitasainak allapota: 
 * 
 * - oldal cime (title), 
 * - morzsamenu (breadcrumb), 
 * - oldal cimsavban megjeleno kep (img)
 * 
 * 
*/

// egy morzsa defiicioja
interface Crumb {
  localize: boolean;
  to: string;
  title: string;
}

export const usePageStore = defineStore('page', {
  state: () => ({
    title: '',
    bgimgurl: '',
    crumbs: [
      {
        localize: true,
        to: '',
        title: '',
      }
    ] as Crumb[],
  }),
  getters: {
    getTitle(): string {
      return this.title
    },
    getBgimgurl(): string {
      return this.bgimgurl
    },
    getCrumbs(): Array<Crumb> {
      return this.crumbs
    },
  },
  actions: {
    setTitle(title: string) {
      this.title = title
    },
    setBgimgurl(bgimgurl: string) {
      this.bgimgurl = bgimgurl
    },
    setCrumbs(crumbs: any) {
      this.crumbs = crumbs
    },
    addCrumb(morecrumb: Crumb) {
      this.crumbs.push(morecrumb)
    },
  },
})